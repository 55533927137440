// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import servicesData from "../components/utility/ServiceData";

// interface ServiceCardProps {
//     title: string;
//     description: string;
//     slug: string;
// }

// export const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, slug }) => {
//     return (
//         <div className="bg-white shadow-md rounded-[30px] p-6 items-center text-center hover:shadow-lg transition-shadow">
//             <h3 className="text-xl font-semibold mb-2">{title}</h3>
//             <p className="text-gray-600 mb-12">{description}</p>
//             <Link to={`/services/${slug}`}>
//                 <span className="text-white bg-blue-800 p-4 rounded-lg mb-4 hover:bg-blue-700">Learn more &rarr;</span>
//             </Link>
//         </div>
//     );
// };

// const BrainServices: React.FC = () => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//         //eslint-disable-next-line
//     }, [])
//     const serviceEntries = Object.entries(servicesData).slice(0, 7);

//     return (
//         <>
//             <Helmet>
//                 <title>Brain Surgeon In Noida & Delhi NCR| Neurosurgeon at Fortis Hospital</title>
//                 <meta name="description" content="Brain surgeon in Noida with experience of over 12 years. Best Neurosurgeon for Head Injuries, Tumors, Aneurysm, Endoscopic Brain Surgery. Call Now." />
//                 <meta name="keywords" content="neurosurgeon, Delhi, Dr. Gangesh Gunjan, brain surgery, spine surgery, neurological disorders, neurosurgery, top neurosurgeon, experienced neurosurgeon" />
//             </Helmet>
//             <div className="container mx-auto px-4 py-8 mt-28">
//                 <h2 className="text-3xl font-bold mb-8 text-center">Brain Services</h2>
//                 <p>
//                 Surgeries related to brain and the complications around it require precise skills and experienced hands. Among the most reliable brain surgeons in Noida and Delhi NCR, Dr Gangesh Gunjan has honed the required skills after stints at the top medical institutes in India, from BLK Hospital in Delhi to Fortis Hospital in Noida.
//                 </p>
//                 <p>
//                 Brain surgeries are conducted by neurosurgeons to treat problems in the brain and the surrounding structures. The problem can be anything from tumors, aneurysms, leaky blood vessels, epilepsy to a serious head injury.
//                 </p>
//                 <p>
//                 If you’re searching for a neurosurgeon in Noida, Delhi NCR or western UP, call now for urgent care or to get a second opinion for an ongoing treatment. 
//                 </p>
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//                     {serviceEntries.map(([slug, service]) => (
//                         <ServiceCard
//                             key={slug}
//                             title={service.title}
//                             description={service.description}
//                             slug={slug}
//                         />
//                     ))}
//                 </div>
//             </div>
//         </>
//     );
// };

// export default BrainServices;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import servicesData from "../components/utility/ServiceData";

interface ServiceCardProps {
    title: string;
    description: string;
    slug: string;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, slug }) => {
    return (
        <div className="bg-white shadow-md rounded-[30px] p-8 flex flex-col justify-between min-h-[300px] hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
            <div>
                <h3 className="text-2xl font-semibold mb-4 text-blue-900">{title}</h3>
                <p className="text-gray-600 leading-relaxed">{description}</p>
            </div>
            <Link to={`/services/${slug}`} className="mt-6 inline-block">
                <span className="text-white bg-blue-800 px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors duration-300 inline-flex items-center gap-2">
                    Learn more <span className="text-lg">&rarr;</span>
                </span>
            </Link>
        </div>
    );
};

const BrainServices: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, [])
    const serviceEntries = Object.entries(servicesData).slice(0, 7);

    return (
        <>
            <Helmet>
                <title>Brain Surgeon In Noida & Delhi NCR| Neurosurgeon at Sarvodaya Hospital</title>
                <meta name="description" content="Brain surgeon in Noida with experience of over 12 years. Best Neurosurgeon for Head Injuries, Tumors, Aneurysm, Endoscopic Brain Surgery. Call Now." />
                <meta name="keywords" content="neurosurgeon, Delhi, Dr. Gangesh Gunjan, brain surgery, spine surgery, neurological disorders, neurosurgery, top neurosurgeon, experienced neurosurgeon" />
            </Helmet>
            <div className="bg-gradient-to-b from-gray-50 to-white">
                <div className="container mx-auto px-4 py-16 mt-28">
                    <div className="max-w-4xl mx-auto mb-16">
                        <h2 className="text-4xl font-bold mb-12 text-center text-blue-900">Brain Services</h2>
                        
                        <div className="space-y-8 text-lg leading-relaxed">
                            <p className="bg-white p-6 rounded-lg shadow-sm border-l-4 border-blue-800">
                                Surgeries related to brain and the complications around it require precise skills and experienced hands. Among the most reliable brain surgeons in Noida and Delhi NCR, Dr Gangesh Gunjan has honed the required skills after stints at the top medical institutes in India, from BLK Hospital in Delhi to Fortis Hospital in Noida.
                            </p>
                            
                            <p className="bg-white p-6 rounded-lg shadow-sm">
                                Brain surgeries are conducted by neurosurgeons to treat problems in the brain and the surrounding structures. The problem can be anything from tumors, aneurysms, leaky blood vessels, epilepsy to a serious head injury.
                            </p>
                            
                            <p className="bg-white p-6 rounded-lg shadow-sm border-l-4 border-blue-800">
                                If you're searching for a neurosurgeon in Noida, Delhi NCR or western UP, call now for urgent care or to get a second opinion for an ongoing treatment. 
                            </p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {serviceEntries.map(([slug, service]) => (
                            <ServiceCard
                                key={slug}
                                title={service.title}
                                description={service.description}
                                slug={slug}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BrainServices;