import React, { useEffect } from "react";
import servicesData from "../components/utility/ServiceData";
import { ServiceCard } from "./BrainServices";
import { Helmet } from "react-helmet";

const SpineServices: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, [])
    const serviceEnteries = Object.entries(servicesData).slice(8, 14);
    return (
        <>
            <Helmet>
                <title>Spine Surgeon in Noida & Delhi NCR | Neurosurgeon at Sarvodaya Hospital</title>
                <meta name="description" content="Spine Surgeon in Noida with experience of over 12 years. Best Neurosurgeon for Spine Injuries, Trauma, Tumors, Dysraphism & minimally invasive surgeries." />
                <meta name="keywords" content="neurosurgeon, Delhi, Dr. Gangesh Gunjan, brain surgery, spine surgery, neurological disorders, neurosurgery, top neurosurgeon, experienced neurosurgeon" />
            </Helmet>
            <div className="bg-gradient-to-b from-gray-50 to-white">
                <div className="container mx-auto px-4 py-16 mt-28">
                    <div className="max-w-4xl mx-auto mb-16">
                        <h2 className="text-4xl font-bold mb-12 text-center text-blue-900">Spine Services</h2>

                        <div className="space-y-8 text-lg leading-relaxed">
                            <p className="bg-white p-6 rounded-lg shadow-sm border-l-4 border-blue-800">
                                Surgical treatment may be necessary in conditions such as spinal deformity, spine tumors, trauma or any degenerative condition. For the desired outcome, you can choose Dr Gangesh Gunjan, who is counted among the best spine surgeons in Noida & Delhi NCR.
                            </p>

                            <p className="bg-white p-6 rounded-lg shadow-sm">
                                Patients opt to go through a spine surgery after extreme symptoms and usually when all other kinds of traditional treatment options such as anti-inflammatory medicines, over-the-counter medications or physical therapies stop working.
                            </p>

                            <p className="bg-white p-6 rounded-lg shadow-sm border-l-4 border-blue-800">
                                The modern technology allows for laser-focussed and precise surgical procedures that ensure minimal incision and fast recovery. Request a consultation now by filling the appointment form.
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {serviceEnteries.map(([slug, service]) => (
                            <ServiceCard
                                key={slug}
                                title={service.title}
                                description={service.description}
                                slug={slug}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SpineServices