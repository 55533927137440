import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const seoContent: { [key: string]: { title: string; description: string; keywords: string } } = {
    '/': {
        title: "Best Neurosurgeon in Noida & Delhi NCR | Sarvodaya Hospital",
        description: "Dr. Gangesh Gunjan, Best Neurosurgeon serving Noida, West UP & Delhi NCR. Experience of 12+ years in brain, spine and nerve surgeries. Consultant at Sarvodaya Hospital, Greater Noida.",
        keywords: "neurosurgeon Noida, brain specialist, spine surgeon, nerve disorder treatment, Dr. Gangesh Gunjan"
    },
    '/top-neurosurgeon-in-bishanpura': {
        title: "Top Neurosurgeon in Bishanpura | Dr. Gangesh Gunjan - Brain & Spine Expert",
        description: "Seeking a neurosurgeon in Bishanpura? Dr. Gangesh Gunjan offers expert care for brain, spine, and nerve conditions. Advanced treatments, personalized approach.",
        keywords: "neurosurgeon Bishanpura, brain surgery Bishanpura, spine specialist near me, Dr. Gangesh Gunjan"
    },
    '/expert-neurosurgeon-prithvi-vihar': {
        title: "Expert Neurosurgeon in Prithvi Vihar - Dr. Gangesh Gunjan",
        description: "Dr. Gangesh Gunjan brings world-class neurosurgical care to Prithvi Vihar. Specializing in brain tumors, spinal disorders, and nerve conditions. Schedule your visit now.",
        keywords: "neurosurgeon Prithvi Vihar, brain tumor specialist, spinal surgery expert, Dr. Gangesh Gunjan"
    },
    '/best-brain-and-spine-surgeon-hapur': {
        title: "Best Brain and Spine Surgeon in Hapur | Dr. Gangesh Gunjan",
        description: "Residents of Hapur, access top-tier neurosurgical treatments with Dr. Gangesh Gunjan. Specialized care for brain injuries, spinal disorders, and nerve conditions.",
        keywords: "neurosurgeon Hapur, brain injury treatment, spine doctor Hapur, Dr. Gangesh Gunjan"
    },
    '/leading-neurosurgeon-bulandshahr': {
        title: "Leading Neurosurgeon for Bulandshahr | Dr. Gangesh Gunjan",
        description: "Dr. Gangesh Gunjan offers advanced neurosurgical solutions to Bulandshahr. Expert in treating complex brain, spine, and nerve disorders. Consult today for personalized care.",
        keywords: "neurosurgeon Bulandshahr, brain disorder specialist, spine treatment Bulandshahr, Dr. Gangesh Gunjan"
    },
    '/best-neurosurgeon-in-greater-noida': {
        title: "Best Neurosurgeon in Greater Noida - Dr. Gangesh Gunjan",
        description: "Trust Dr. Gangesh Gunjan for exceptional neurosurgical care in Greater Noida. Specializing in minimally invasive procedures for brain, spine, and nerve conditions.",
        keywords: "neurosurgeon Greater Noida, minimally invasive brain surgery, spine specialist Greater Noida, Dr. Gangesh Gunjan"
    }

};

const SEOHelmet: React.FC = () => {
    const location = useLocation();
    const { title, description, keywords } = seoContent[location.pathname] || seoContent['/'];

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://drgangeshgunjanneuro.com/" />
            <meta property="og:location" content="Delhi NCR, Noida, Greator Noida, Mayur Vihar, Ashok Nagar, Hapur, BulandShahr, Prithvi Vihar, Bishanpura" />
        </Helmet>
    );
};

export default SEOHelmet;