import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import '../../index.css';
import { motion } from 'framer-motion';

const Footer: React.FC = () => {
  return (
    <footer className="footer bg-white py-12 relative text-zinc-700 text-base md:text-lg leading-relaxed tracking-wide">
      <motion.div className="container mx-auto px-4">
        <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <motion.div className="text-zinc-700">
            <h3 className="text-2xl font-bold mb-6 text-blue-600">Dr. Gangesh Gunjan</h3>
            <p className="mb-1">Sarvodaya Hospital</p>
            <p className='mb-1'>Sector 16C, Gaur City 2, Greater Noida,</p>
            <p className='mb-4'>Uttar Pradesh 201009, India</p>
            <motion.div className="flex space-x-4">
              <a href="/#" className="text-zinc-700 hover:text-blue-400 transition-colors duration-300">
                <FaFacebookF size={20} />
              </a>
              <a href="/#" className="text-zinc-700 hover:text-blue-400 transition-colors duration-300">
                <FaTwitter size={20} />
              </a>
              <a href="/#" className="text-zinc-700 hover:text-blue-400 transition-colors duration-300">
                <FaLinkedinIn size={20} />
              </a>
            </motion.div>
          </motion.div>
          <motion.div>
            <h3 className="text-2xl font-bold mb-6 text-blue-600">Contact</h3>
            <p className="flex items-center mb-3">
              <FaMapMarkerAlt className="mr-3 text-blue-400" />
              Sector 16C, Gaur City 2, Greater Noida, Uttar Pradesh 201009
            </p>
            <p className="flex items-center mb-3">
              <FaPhoneAlt className="mr-3 text-blue-400" />
              +91-7727834367
            </p>
            <p className="flex items-center mb-3">
              <FaEnvelope className="mr-3 text-blue-400" />
              gangeshgunjan97@gmail.com
            </p>
          </motion.div>
          <motion.div className="text-gray-300">
            <h3 className="text-2xl font-bold mb-6 text-blue-600">Opening Hours</h3>
            <p className='text-base text-gray-600'>Monday-Saturday : 9 am - 5 pm</p>
            <p className='text-sm font-bold text-zinc-700'>Sarvodaya Hospital, Gaur City</p>
            <p className='text-sm text-zinc-600'>Mon-Sat: 9 am - 5 pm</p>
             
          </motion.div>
          <motion.div>
            <h3 className="text-2xl font-bold mb-6 text-blue-600">Locations</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/top-neurosurgeon-in-bishanpura" className="text-zinc-700 hover:text-blue-400 transition-colors duration-300">Bishanpura</Link>
              </li>
              <li>
                <Link to="/expert-neurosurgeon-prithvi-vihar" className="text-zinc-700 hover:text-blue-400 transition-colors duration-300">Prithvi Vihar</Link>
              </li>
              <li>
                <Link to="/best-brain-and-spine-surgeon-hapur" className="text-zinc-700 hover:text-blue-400 transition-colors duration-300">Hapur</Link>
              </li>
              <li>
                <Link to="/leading-neurosurgeon-bulandshahr" className="text-zinc-700 hover:text-blue-400 transition-colors duration-300">Bulandshahr</Link>
              </li>
              <li>
                <Link to="/best-neurosurgeon-in-greater-noida" className="text-zinc-700 hover:text-blue-400 transition-colors duration-300">Greater Noida</Link>
              </li>
            </ul>
          </motion.div>
        </motion.div>
        <motion.div className="mt-12 pt-8 border-t border-gray-700 text-center text-gray-400">
          <p className="mb-4">&copy; {new Date().getFullYear()} Dr. Gangesh Gunjan. All rights reserved.</p>
          <p className="text-sm">
            Website by <a href="https://rnahealthtech.com" className="text-zinc-700 hover:underline">RNA HealthTech</a>
          </p>
        </motion.div>
      </motion.div>
    </footer>
  );
};

export default Footer;
