import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const About: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        //eslint disable next line
    }, [])

    const education = [
        'Neuroendoscopy Fellowship from Neuroendoscopic Society of India',
        'M.Ch (Neurosurgery) from S.M.S. Medical College, Rajasthan University of Health Sciences, Rajasthan',
        'M.S. (General Surgery) from G.S.V.M. Medical College, C.S.J.M. University, Kanpur, Uttar Pradesh',
        'M.B.B.S. from M.G.M. Medical College, Jamshedpur, Ranchi University, Jharkhand with Honours in Pharmacology and Gold Medals in Anatomy and Physiology'
    ];

    const careerHistory = [
        'Senior Consultant Neurosurgeon at Sarvodaya Hospital, Greater Noida (February 2025 - Present)',
        'Consultant & teaching faculty for DrNB, Neurosurgery at Fortis Hospital, Noida (October 2016 - February 2025)',
        'Three years Residency in Neurosurgery at S.M.S. Medical College and Hospital, Jaipur (August 2013 - July 2016)',
        'One year Senior Residency in Neurosurgery at Baba Saheb Ambedkar Hospital, New Delhi (July 2012 - June 2013)',
        'Nine months Senior Residency in Neurosurgery at B.L. Kapur Memorial Hospital, New Delhi (June 2010 - March 2011)',
        'Three years Junior Residency in General Surgery at G.S.V.M. Medical College and Hospital, Kanpur (May 2007 - April 2010)'
    ];

    const experienceInNeuroSurgery = [
        {
            title: 'Brain Surgery',
            description: 'Dr. Gunjan specializes in complex brain surgeries, utilizing state-of-the-art technology and traditional techniques to ensure optimal outcomes for patients with various neurological conditions.'
        },
        {
            title: 'Spine Surgery',
            description: 'With extensive experience in spine surgeries, Dr. Gunjan offers advanced treatments for a wide range of spinal disorders, focusing on minimally invasive techniques when possible.'
        },
        {
            title: 'Neuroendoscopy',
            description: 'Dr. Gunjan is skilled in neuroendoscopic procedures, offering less invasive options for treating various brain and spinal conditions, resulting in faster recovery times for patients.'
        },
        {
            title: 'Precision Neurosurgery',
            description: 'Combining traditional techniques with cutting-edge technology, Dr. Gunjan ensures high precision in all surgical procedures, prioritizing patient safety and optimal outcomes.'
        },
        {
            title: 'Patient-Centered Care',
            description: 'Dr. Gunjan believes in providing compassionate care, offering a sympathetic ear and support throughout the treatment journey, recognizing the emotional aspects of brain and spine surgeries.'
        }
    ];

    const papersAndPresentations = [
        {

            title: "Asymptomatic posterior cervical myelomeningocele with tethered cord in an adolescent: a rare form of spinal dysraphism with rare presentation.",
            publication: "Romanian Journal of Neurosurgery (2016) XXX 1",
            type: "Published Paper"
        },
        {
            title: "Pituitary apoplexy in setting of Dengue haemorrhagic fever with thrombocytopenia: Case report and review of literature.",
            publication: "Romanian Journal of Neurosurgery (2018) vol.XXXII no.2",
            type: "Published Paper"
        },
        {
            title: "Life threatening intracerebral haemorrhage in previously undiagnosed acute T-cell lymphoblastic leukemia with blast crisis: A Nightmare in Neurosurgeon's Life?",
            publication: "Pediatric Neurosurgery (2017) 52(4)",
            type: "Published Paper"
        },
        {
            title: "Pseudomeningocele after baclofen pump pump placement in a child: A case report and preventive measures.",
            publication: "Romanian Journal of Neurosurgery (2017) vol. XXX1 no.4",
            type: "Published Paper"
        },
        {
            title: "Diffuse Axonal Injury (DAI) : Diagnostic and Prognostic Significance of various sequences of Magnetic Resonance Imaging (MRI)",
            publication: "Accepted for publication in Neurology India",
            type: "Research Paper"
        },
        {
            title: "Changing trends in Craniovertebral Junction fixation",
            event: "Spine 2017, New Delhi",
            type: "Paper Presentation"
        },
        {
            title: "Re do Lumbar Spine Surgery – Nuances & Techniques",
            event: "SPINE CONGRESS 2019, New Delhi",
            type: "Paper Presentation"
        },
        {
            title: "Post Traumatic Pseudoaneurysm of V4 Segment of Vertebral Artery",
            event: "12th International Conference of Cerebrovascular Surgery at Mumbai, 26-28th February 2016",
            type: "Poster Presentation"
        },
        {
            title: "Extent of Surgery in the Management of Cystosarcoma Phyllodes",
            event: "35th Annual Conference of UP Chapter of Association of Surgeons of India at Lucknow, 6-8th November 2009",
            type: "Paper Presentation"
        }
    ];

    const affiliations = [
        "Life member of Neurological Society of India [NSI]",
        "Life member of Neurological Surgeon Society of India [NSSI]",
        "Life Member of Neurotrauma Society of India [NTSI]",
        "Member of European Association of Neurosurgical Societies [EANS]"
    ];


    return (
        <div className="min-h-screen relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-b from-rose-50 via-white to-sky-100 -z-10"></div>
            <div className="absolute inset-0 bg-[url('data:image/svg+xml;charset=utf-8,...')] opacity-20 -z-10"></div>

            <Helmet>
                <title>Senior Consultant at Sarvodaya Hospital, Greater Noida | 12+ years experience</title>
                <meta name="description" content="Neurosurgeon serving Noida, West UP & Delhi NCR | Gold Medalist Consultant at Fortis Hospital Noida with experience in Brain, Spine & Nerve Surgeries." />
                <meta name="keywords" content="neurosurgeon, Delhi, Dr. Gangesh Gunjan, brain surgery, spine surgery, neurological disorders, neurosurgery, top neurosurgeon, experienced neurosurgeon" />
            </Helmet>

            <div className="relative">

                <div className="absolute inset-0 flex items-center justify-center">
                    <h1 className="text-5xl font-bold text-white text-center shadow-text">Meet Dr. Gangesh Gunjan</h1>
                </div>


                <div className="container mx-auto px-4 mt-60">
                    <div className="bg-white shadow-xl rounded-lg overflow-hidden mb-12 -mt-32 relative z-10 transition-all duration-300 hover:shadow-2xl">
                        <div className="md:flex">
                            <div className="md:w-1/3">
                                <img
                                    src="/images/dr-gangesh.jpeg"
                                    alt="Dr. Gangesh Gunjan"
                                    className="w-full h-84 object-cover"
                                />
                            </div>
                            <div className="md:w-2/3 p-8">
                                <h2 className="text-4xl font-bold mb-4 mt-24 text-gray-900">Dr. Gangesh Gunjan</h2>
                                <p className="text-2xl mb-4 text-gray-700">Senior Consultant Neurosurgeon</p>
                                <p className="text-xl mb-6 text-gray-600">
                                    Total Experience: <span className="text-2xl font-bold text-gray-600">12 years</span>
                                </p>
                                <p className="text-lg text-gray-600 leading-relaxed">
                                Dr Gangesh Gunjan is one of the most prominent neurosurgeons in Noida with over a decade of experience. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 py-12">

                <section className="mb-16 bg-gradient-to-br from-white to-red-50 rounded-lg shadow-lg p-8 transition-all duration-300 hover:shadow-xl">
                    <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">Educational Qualifications</h2>
                    <div className="flex flex-col md:flex-row-reverse items-center">

                        <div className="md:w-2/3">
                            <ul className="space-y-4">
                                {education.map((edu, index) => (
                                    <li key={index} className="text-lg text-gray-700 leading-relaxed">
                                        <span className="font-semibold text-gray-800">{edu.split(' from ')[0]}</span>
                                        <br />
                                        <span className="text-gray-600">from {edu.split(' from ')[1]}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="mb-16 bg-gradient-to-br from-white to-blue-50 border-2 border-gray-200 rounded-lg shadow-lg p-8 transition-all duration-300 hover:shadow-xl">
                    <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">Interest Areas</h2>

                    <div className="flex flex-col lg:flex-row items-center gap-12">


                        <div className="lg:w-2/3">
                            {experienceInNeuroSurgery.map((area, index) => (
                                <details key={index} className="mb-6 group">
                                    <summary className="text-2xl font-semibold text-gray-800 cursor-pointer hover:text-blue-600 transition-colors duration-300">
                                        {area.title}
                                    </summary>
                                    <p className="mt-4 text-gray-700 leading-relaxed text-lg pl-6 border-l-4 border-blue-200">
                                        {area.description}
                                    </p>
                                </details>
                            ))}
                        </div>
                    </div>
                </section>



                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-6 text-gray-900">Career History</h2>
                    <ul className="space-y-4">
                        {careerHistory.map((position, index) => (
                            <li key={index} className="text-lg text-gray-700 leading-relaxed">
                                {position}
                            </li>
                        ))}
                    </ul>
                </section>
                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-6 text-gray-900">Papers, Posters, and Presentations</h2>
                    <div className="space-y-6">
                        {papersAndPresentations.map((item, index) => (
                            <div key={index} className="border-l-4 border-blue-500 pl-4">
                                <h3 className="text-xl font-semibold text-gray-800 mb-2">{item.title}</h3>
                                <p className="text-gray-700">
                                    <span className="font-medium">{item.type}: </span>
                                    {item.publication || item.event}
                                </p>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-6 text-gray-900">Professional Affiliations</h2>
                    <ul className="list-disc list-inside space-y-2">
                        {affiliations.map((affiliation, index) => (
                            <li key={index} className="text-lg text-gray-700 leading-relaxed">
                                {affiliation}
                            </li>
                        ))}
                    </ul>
                </section>
            </div>
        </div>
    );
};

export default About;