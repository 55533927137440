import React from 'react';
import SEOHelmet from '../SEOHelmet';

const BishanpuraPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [])
  
  return (
    <div className='mt-28 mb-20 max-w-4xl mx-auto px-4'>
      <SEOHelmet   />
      <h1 className='text-3xl md:text-5xl font-bold text-center mb-8'>Dr. Gangesh Gunjan: Bishanpura's Premier Neurosurgeon</h1>
      
      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Expert Neurosurgical Care in Bishanpura</h2>
        <p className='text-lg'>Dr. Gangesh Gunjan brings world-class neurosurgical expertise to Bishanpura, offering advanced treatments for complex neurological conditions. With a focus on innovative techniques and compassionate care, Dr. Gunjan is setting new standards in neurosurgery for the Bishanpura community.</p>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Comprehensive Neurosurgical Services</h2>
        <ul className='list-disc pl-6 text-lg'>
          <li>Advanced Brain Tumor Surgery
            <ul className='list-circle pl-6 mt-2'>
              <li>Awake craniotomy for eloquent cortex tumors</li>
              <li>Endoscopic transsphenoidal surgery for pituitary tumors</li>
              <li>Intraoperative neurophysiological monitoring</li>
            </ul>
          </li>
          <li>Cutting-edge Spine Disorder Treatments
            <ul className='list-circle pl-6 mt-2'>
              <li>Minimally invasive spine surgery (MISS)</li>
              <li>Artificial disc replacement</li>
              <li>Complex spinal deformity correction</li>
            </ul>
          </li>
          <li>State-of-the-art Neurovascular Surgery
            <ul className='list-circle pl-6 mt-2'>
              <li>Endovascular treatment of aneurysms and AVMs</li>
              <li>Carotid endarterectomy and stenting</li>
              <li>Bypass surgery for moyamoya disease</li>
            </ul>
          </li>
          <li>Specialized Pediatric Neurosurgery
            <ul className='list-circle pl-6 mt-2'>
              <li>Treatment of hydrocephalus and spina bifida</li>
              <li>Pediatric brain tumor resection</li>
              <li>Correction of craniofacial abnormalities</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Why Choose Dr. Gangesh Gunjan in Bishanpura?</h2>
        <ul className='list-disc pl-6 text-lg'>
          <li>Extensive experience in complex neurosurgical procedures</li>
          <li>Utilization of advanced surgical techniques for optimal outcomes</li>
          <li>State-of-the-art clinic equipped with cutting-edge technology</li>
          <li>Personalized treatment plans tailored to each patient's needs</li>
          <li>Compassionate approach to patient care and family support</li>
          <li>Ongoing research and adoption of innovative neurosurgical methods</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Advanced Facilities in Bishanpura</h2>
        <p className='text-lg'>Our Bishanpura clinic is equipped with:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>High-resolution MRI and CT imaging for precise diagnostics</li>
          <li>Neuronavigation systems for accurate surgical planning</li>
          <li>Intraoperative MRI for real-time tumor visualization</li>
          <li>Advanced neurosurgical microscopes for enhanced precision</li>
          <li>State-of-the-art neuro-intensive care unit for post-operative care</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Patient-Centric Approach</h2>
        <p className='text-lg'>Dr. Gunjan and his team are committed to providing:
          <ul className='list-disc pl-6 mt-2'>
            <li>Detailed pre-operative counseling and education</li>
            <li>Transparent communication throughout the treatment process</li>
            <li>Comprehensive post-operative care and rehabilitation support</li>
            <li>Regular follow-ups to ensure optimal recovery</li>
          </ul>
        </p>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Schedule Your Consultation in Bishanpura</h2>
        <p className='text-lg'>Experience world-class neurosurgical care with Dr. Gangesh Gunjan, Bishanpura's leading neurosurgeon. To book an appointment, please call <a href="tel:+917727834367" className='text-blue-600 hover:underline'>+91-7727834367</a> or visit our clinic at Sarvodaya Hospital, Greater Noida.</p>
      </section>
    </div>
  );
};

export default BishanpuraPage;