import React from 'react';
import SEOHelmet from '../SEOHelmet';

const BulandshahrPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [])
  
  return (
    <div className='mb-20 mt-28 max-w-4xl mx-auto px-4'>
      <SEOHelmet
      />
      <h1 className='text-3xl md:text-5xl font-bold text-center mb-8'>Dr. Gangesh Gunjan: Bulandshahr's Renowned Neurosurgeon</h1>
      
      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Exceptional Neurosurgical Expertise in Bulandshahr</h2>
        <p className='text-lg'>Dr. Gangesh Gunjan brings his exceptional skills as a top-tier neurosurgeon to Bulandshahr, offering advanced treatments for a wide range of neurological disorders. With a commitment to excellence and patient-centered care, Dr. Gunjan is elevating the standard of neurosurgical services in the region.</p>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Pioneering Neurological Treatments</h2>
        <ul className='list-disc pl-6 text-lg'>
          <li>Frameless stereotactic radiosurgery for brain tumors
            <ul className='list-circle pl-6 mt-2'>
              <li>Precise targeting of tumors with minimal damage to surrounding tissue</li>
              <li>Suitable for deep-seated and previously inoperable tumors</li>
            </ul>
          </li>
          <li>Minimally invasive tubular microdiscectomy for lumbar disc herniation
            <ul className='list-circle pl-6 mt-2'>
              <li>Faster recovery and reduced post-operative pain</li>
              <li>Improved cosmetic results with smaller incisions</li>
            </ul>
          </li>
          <li>Neuromodulation techniques for chronic pain and movement disorders
            <ul className='list-circle pl-6 mt-2'>
              <li>Deep Brain Stimulation (DBS) for Parkinson's disease and essential tremor</li>
              <li>Spinal Cord Stimulation (SCS) for chronic neuropathic pain</li>
            </ul>
          </li>
          <li>Endovascular coiling for cerebral aneurysms
            <ul className='list-circle pl-6 mt-2'>
              <li>Minimally invasive treatment to prevent aneurysm rupture</li>
              <li>Reduced risk compared to traditional open surgery</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Comprehensive Neurosurgical Care in Bulandshahr</h2>
        <p className='text-lg'>Our Bulandshahr clinic is equipped with cutting-edge diagnostic and therapeutic tools, allowing Dr. Gunjan to provide:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>High-resolution neuroimaging for precise diagnoses</li>
          <li>Advanced neuronavigation systems for surgical planning</li>
          <li>Intraoperative neurophysiological monitoring for enhanced safety</li>
          <li>State-of-the-art neurosurgical microscopes for intricate procedures</li>
          <li>Comprehensive pre- and post-operative care facilities</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Expertly Managed Neurological Conditions</h2>
        <ul className='list-disc pl-6 text-lg'>
          <li>Spinal cord tumors and spinal dysraphism</li>
          <li>Trigeminal neuralgia and other cranial nerve disorders</li>
          <li>Hydrocephalus and cerebrospinal fluid disorders</li>
          <li>Neurodegenerative diseases requiring surgical intervention</li>
          <li>Complex spinal deformities and degenerative conditions</li>
          <li>Traumatic brain and spinal cord injuries</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Advanced Spine Surgery in Bulandshahr</h2>
        <p className='text-lg'>Dr. Gunjan specializes in complex spine surgeries, offering hope to patients with severe spinal deformities, degenerative conditions, and spinal trauma. Our services include:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Minimally invasive spinal fusion techniques</li>
          <li>Artificial disc replacement for cervical and lumbar spine</li>
          <li>Correction of scoliosis and other spinal deformities</li>
          <li>Spinal cord tumor resection</li>
          <li>Treatment of spinal infections and vertebral fractures</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Pediatric Neurosurgery Excellence</h2>
        <p className='text-lg'>We provide specialized care for children with neurological conditions, including:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Congenital malformations of the brain and spine</li>
          <li>Pediatric brain tumors</li>
          <li>Craniofacial disorders and craniosynostosis</li>
          <li>Pediatric epilepsy requiring surgical intervention</li>
          <li>Hydrocephalus and shunt procedures</li>
        </ul>
        <p className='text-lg mt-4'>Our child-friendly environment ensures a comfortable experience for young patients and their families.</p>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Comprehensive Neurological Rehabilitation</h2>
        <p className='text-lg'>In addition to surgical interventions, our Bulandshahr clinic offers:</p>
        <ul className='list-disc pl-6 text-lg'>
          <li>Personalized neurorehabilitation programs</li>
          <li>Collaboration with skilled physiotherapists and occupational therapists</li>
          <li>Cognitive rehabilitation for brain injury patients</li>
          <li>Speech and language therapy for relevant neurological conditions</li>
          <li>Ongoing support for patients and their families</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Schedule Your Consultation in Bulandshahr</h2>
        <p className='text-lg'>Experience world-class neurological care with Dr. Gangesh Gunjan, Bulandshahr's leading neurosurgeon. To book an appointment, please call <a href="tel:+917727834367" className='text-blue-600 hover:underline'>+91-7727834367</a> or visit our clinic at Sarvodaya Hospital, Noida.</p>
      </section>
    </div>
  );
};

export default BulandshahrPage;