import React from 'react';
import SEOHelmet from '../SEOHelmet';

const PrithviViharPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [])
  
  return (
    <div className='mt-28 mb-20 text-center'>
      <SEOHelmet  />
      <h1 className='text-2xl md:text-4xl lg:text-6xl'>Dr. Gangesh Gunjan: Leading Neurosurgeon in Delhi NCR</h1>
      
      <section className='my-8'>
        <h2 className='text-xl md:text-3xl lg:text-4xl mb-4'>Expert Neurosurgical Care in Prithvi Vihar, Noida</h2>
        <p className='text-lg'>Dr. Gangesh Gunjan, with over a decade of experience, is one of the best neurosurgeons in Delhi NCR. Trusted by hundreds of patients since 2010, he offers cutting-edge treatments for brain, spine, and nerve disorders at Sarvodaya Hospital, Noida.</p>
      </section>

      <section className='my-8'>
        <h2 className='text-xl md:text-3xl lg:text-4xl mb-4'>Specialized Neurosurgical Treatments</h2>
        <ul className='list-disc list-inside text-left max-w-2xl mx-auto'>
          <li>Minimally invasive brain and spine surgery</li>
          <li>Deep Brain Stimulation (DBS) for movement disorders</li>
          <li>Endoscopic skull base surgery for brain tumors</li>
          <li>Advanced spine treatments including artificial disc replacement</li>
          <li>Stereotactic radiosurgery for precision treatment</li>
        </ul>
      </section>

      <section className='my-8'>
        <h2 className='text-xl md:text-3xl lg:text-4xl mb-4'>Why Choose Dr. Gangesh Gunjan?</h2>
        <p className='text-lg'>As a top neuro expert and spine specialist, Dr. Gunjan combines personalized care with state-of-the-art techniques. His expertise in neuromodulation and functional neurosurgery makes him a sought-after brain doctor and spine doctor in the region.</p>
      </section>

      <section className='my-8'>
        <h2 className='text-xl md:text-3xl lg:text-4xl mb-4'>Conditions Treated</h2>
        <ul className='list-disc list-inside text-left max-w-2xl mx-auto'>
          <li>Brain tumors and glioblastoma</li>
          <li>Parkinson's disease and movement disorders</li>
          <li>Spinal stenosis and herniated discs</li>
          <li>Trigeminal neuralgia and chronic pain syndromes</li>
          <li>Complex neurological disorders</li>
        </ul>
      </section>

      <section className='my-8'>
        <h2 className='text-xl md:text-3xl lg:text-4xl mb-4'>Advanced Neurosurgical Facilities</h2>
        <p className='text-lg'>Our clinic in Prithvi Vihar, Noida is equipped with cutting-edge technology, including high-field MRI and intraoperative neuromonitoring, ensuring precise diagnosis and optimal surgical outcomes.</p>
      </section>

      <section className='my-8'>
        <h2 className='text-xl md:text-3xl lg:text-4xl mb-4'>Patient-Centered Approach</h2>
        <p className='text-lg'>Dr. Gunjan's holistic approach to neurosurgical care ensures comprehensive support from pre-operative counseling to post-operative rehabilitation, guaranteeing the best possible recovery for every patient.</p>
      </section>

      <section className='my-8'>
        <h2 className='text-xl md:text-3xl lg:text-4xl mb-4'>Contact for Consultation</h2>
        <p className='text-lg'>To schedule an appointment with Dr. Gangesh Gunjan, the top neurosurgeon in Delhi NCR, call +91-7727834367 or visit Sarvodaya Hospital, Noida. Take the first step towards expert neurological care today.</p>
      </section>
    </div>
  );
};

export default PrithviViharPage;